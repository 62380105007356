import React, { useState, useEffect, useRef } from 'react';
import { useSpring, animated, config } from '@react-spring/web';
import { useMediaQuery } from 'react-responsive';
import CosmicInfoSection from './CosmicInfo';
import CosmicFooter from './Footer';

const UniverseBubble = ({ video, position, size, scrollY }) => {
  const [isHovered, setIsHovered] = useState(false);

  const { y } = useSpring({
    y: scrollY * (Math.random() * 0.2 + 0.1),
    config: { mass: 1, tension: 280, friction: 60 }
  });

  const hoverSpring = useSpring({
    scale: isHovered ? 1.1 : 1,
    config: config.wobbly,
  });

  return (
    <animated.div
      className="absolute rounded-full overflow-hidden cursor-pointer"
      style={{
        ...position,
        width: size,
        height: size,
        transform: y.to(y => `translate3d(0, ${y}px, 0)`),
        ...hoverSpring,
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <video
        src={video}
        autoPlay
        loop
        muted
        playsInline
        className="w-full h-full object-cover"
      />
    </animated.div>
  );
};

const MultiversePortfolio = () => {
  const [universes, setUniverses] = useState([]);
  const [scrollY, setScrollY] = useState(0);
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const containerRef = useRef(null);

  const videoSources = [
    'media/dance.mp4',
    'media/vogue_runway.mp4',
    'media/natgeo.mp4'
  ];

  const generateStaggeredSizes = (count, minSize, maxSize) => {
    return Array.from({ length: count }, (_, i) => {
      const t = i / (count - 1);
      return Math.round(minSize + t * (maxSize - minSize));
    });
  };

  const generateNonOverlappingPosition = (existingUniverses, size, containerWidth, containerHeight) => {
    const margin = 20;
    let attempts = 0;
    const maxAttempts = 200;

    while (attempts < maxAttempts) {
      const left = Math.random() * (containerWidth - size);
      const top = Math.random() * (containerHeight - size);

      const overlaps = existingUniverses.some(universe => {
        const dx = left - universe.position.left;
        const dy = top - universe.position.top;
        const distance = Math.sqrt(dx * dx + dy * dy);
        return distance < (size / 2 + parseInt(universe.size) / 2 + margin);
      });

      if (!overlaps) {
        return { left: `${left}px`, top: `${top}px` };
      }

      attempts++;
    }

    return null;
  };

  useEffect(() => {
    const numUniverses = isMobile ? 5 : 9;
    const minSize = isMobile ? 60 : 80;
    const maxSize = isMobile ? 100 : 150;
    const sizes = generateStaggeredSizes(numUniverses, minSize, maxSize);

    const containerWidth = containerRef.current?.clientWidth || window.innerWidth;
    const containerHeight = containerRef.current?.clientHeight || 600;

    const newUniverses = [];

    for (let i = 0; i < numUniverses; i++) {
      const size = sizes[i];
      const position = generateNonOverlappingPosition(newUniverses, size, containerWidth, containerHeight);

      if (position) {
        newUniverses.push({
          id: i,
          video: videoSources[i % 3],
          position,
          size: `${size}px`,
        });
      }
    }

    setUniverses(newUniverses);

    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isMobile]);

  return (
    <div className="min-h-screen bg-gradient-to-b from-[#301934] to-[#301934]">
      <div className="h-[45vh]" /> {/* Spacer to push content down */}
      
      <header className="text-center px-4 mb-20">
        <h1 className="text-3xl md:text-5xl lg:text-6xl font-light text-white" style={{ fontFamily: 'Space Grotesk, sans-serif' }}>
          Crafting Virtual Universes,<br />Shaping Brand Realities
        </h1>
      </header>
      
      <div className="h-[10vh]" /> {/* Additional spacer between header and bubbles */}
      
      <div className="relative h-[600px] overflow-hidden" ref={containerRef}>
        {universes.map((universe) => (
          <UniverseBubble key={universe.id} {...universe} scrollY={scrollY} />
        ))}
      </div>
      
      <div className="py-16">
        <CosmicInfoSection />
      </div>
      <CosmicFooter />
    </div>
  );
};

export default MultiversePortfolio;
