import React, { useState } from 'react';
import { Menu, X } from 'lucide-react';
import { Link } from 'react-router-dom';
import BusinessCardModal from './BusinessCard';
import "@fontsource/space-grotesk";

const IFramePopup = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
      <div className="bg-white p-4 rounded-lg w-full max-w-2xl">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">Claim a Free Ishaverse Item</h2>
          <button onClick={onClose} className="text-black">
            <X size={24} />
          </button>
        </div>
        <iframe
          src="https://embed.ipfscdn.io/ipfs/bafybeicd3qfzelz4su7ng6n523virdsgobrc5pcbarhwqv3dj3drh645pi/?contract=0xe22F510021D4E844eea7958C9114C5D14A4Ed131&chain=%7B%22name%22%3A%22Polygon+Mainnet%22%2C%22chain%22%3A%22Polygon%22%2C%22rpc%22%3A%5B%22https%3A%2F%2F137.rpc.thirdweb.com%2F%24%7BTHIRDWEB_API_KEY%7D%22%5D%2C%22nativeCurrency%22%3A%7B%22name%22%3A%22MATIC%22%2C%22symbol%22%3A%22MATIC%22%2C%22decimals%22%3A18%7D%2C%22shortName%22%3A%22matic%22%2C%22chainId%22%3A137%2C%22testnet%22%3Afalse%2C%22slug%22%3A%22polygon%22%2C%22icon%22%3A%7B%22url%22%3A%22ipfs%3A%2F%2FQmRNqgazYuxUa5WdddFPftTWiP3KwzBMgV9Z19QWnLMETc%22%2C%22width%22%3A2000%2C%22height%22%3A2000%2C%22format%22%3A%22png%22%7D%7D&clientId=4e9fccf98312f0a66227b91b568ae0b1&theme=light&primaryColor=purple"
          width="100%"
          height="500px"
          style={{maxWidth: "100%"}}
          frameBorder="0"
        />
      </div>
    </div>
  );
};

const CosmicNavbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isIFrameOpen, setIsIFrameOpen] = useState(false);

  const navItems = [
    { name: 'Projects', href: '/portfolio' },
    { name: 'Resume', href: '/isha_resume.pdf', download: true },
    { name: 'Web3', action: () => setIsIFrameOpen(true) },
    { name: 'Contact', href: '#contact', action: () => setIsModalOpen(true) },
  ];

  const handleNavItemClick = (item, e) => {
    if (item.download) {
      e.preventDefault();
      const link = document.createElement('a');
      link.href = item.href;
      link.download = 'Isha_Desai_Resume.pdf';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else if (item.action) {
      e.preventDefault();
      item.action();
    }
    setIsOpen(false);
  };

  return (
    <>
      <nav className="fixed w-full z-40 bg-[#301934] bg-opacity-10 backdrop-blur-md">
        <div className="max-width-7xl mx-auto px-4 py-2">
          <div className="flex items-center justify-between">
            <Link to="/" className="text-2xl font-bold text-white" style={{ fontFamily: 'Space Grotesk, sans-serif' }}>
              Isha Desai
            </Link>
            <div className="hidden md:flex space-x-4">
              {navItems.map((item) => (
                <Link
                  key={item.name}
                  to={item.href}
                  onClick={(e) => handleNavItemClick(item, e)}
                  className="px-3 py-2 text-white"
                  style={{ fontFamily: 'Space Grotesk, sans-serif' }}
                >
                  {item.name}
                </Link>
              ))}
            </div>
            <button
              className="md:hidden text-white"
              onClick={() => setIsOpen(!isOpen)}
            >
              {isOpen ? <X size={24} /> : <Menu size={24} />}
            </button>
          </div>
        </div>
        {isOpen && (
          <div className="md:hidden bg-[#301934] bg-opacity-90">
            <div className="px-4 py-2 space-y-2">
              {navItems.map((item) => (
                <Link
                  key={item.name}
                  to={item.href}
                  onClick={(e) => handleNavItemClick(item, e)}
                  className="block py-2 text-white"
                  style={{ fontFamily: 'Space Grotesk, sans-serif' }}
                >
                  {item.name}
                </Link>
              ))}
            </div>
          </div>
        )}
      </nav>
      <BusinessCardModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
      <IFramePopup isOpen={isIFrameOpen} onClose={() => setIsIFrameOpen(false)} />
    </>
  );
};

export default CosmicNavbar;
