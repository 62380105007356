import React from 'react';
import { ArrowDownToLine } from 'lucide-react';
import CosmicFooter from './Footer';

const BentoCard = ({ project, className }) => (
  <div className={`relative overflow-hidden rounded-xl ${className}`}>
    <img
      src={project.image}
      alt={project.title}
      className="w-full h-full object-cover"
    />
    <div className="absolute inset-0 bg-gradient-to-t from-black/80 to-transparent flex flex-col justify-end p-6">
      <a
        href={project.pdfLink}
        download
        className="absolute top-4 right-4 inline-flex items-center justify-center bg-black/40 text-white py-2 px-4 rounded-full font-[Space_Grotesk] hover:bg-black/60 transition-colors text-sm"
      >
        <ArrowDownToLine size={16} className="mr-2" />
        Download PDF
      </a>
      <h2 className="text-2xl font-bold mb-2 font-[Space_Grotesk] text-white">
        {project.title}
      </h2>
      <p className="text-sm mb-4 font-[Space_Grotesk] text-white line-clamp-3">
        {project.description}
      </p>
    </div>
  </div>
);

const PortfolioPage = () => {
  const projects = [
    {
      title: 'NatGeo Wild World',
      description: '"Spark Curiosity, Empower Exploration, and Inspire Change." Where better for National Geographic to execute their mission than in the ever expanding world of Roblox?',
      pdfLink: 'natgeo_partnership.pdf',
      image: 'media/natgeo.png',
    },
    {
      title: 'Virtual Worlds for Learning',
      description: 'The Potential Effects of Nuanced Social Interactions and Identities in Virtual Worlds on Learning',
      pdfLink: 'learning.pdf',
      image: 'media/roblox.png',
    },
    {
      title: 'Navigating Virtual Realities',
      description: 'This research examines the social dynamics and identity formation in a fully virtual classroom using Oculus Quest 2. Interviews with a professor and student reveal how virtual interactions enhance learning, despite the initial challenges of adapting to the technology.',
      pdfLink: 'interview.pdf',
      image: 'media/interview.png',
    },
    {
      title: 'Teen Vogue: Roblox Fashion Week',
      description: 'Proposal: Teen Vogue partners with Roblox to redefine teen fashion and digital culture, solidifying its role as the ultimate voice in youth trends.',
      pdfLink: 'teen_vogue.pdf',
      image: 'media/roblox_runway.png',
    },
    {
      title: 'Immersive Advertising', // Replace with actual title
      description: 'This paper, presented to the CRAFT Media C-Suite, explores the evolution and impact of advertising within the metaverse, examining how immersive technologies are reshaping consumer engagement, data collection, and the ethical considerations surrounding targeted advertising in virtual environments.', // Replace with actual description
      pdfLink: 'multiverse.pdf', // Replace with actual PDF link
      image: 'media/multiverse.png', // Replace with actual image path
    },
  ];

  return (
    <div className="min-h-screen bg-[#301934] flex flex-col">
      <div className="flex-grow p-4 md:p-8 pt-16 md:pt-24">
        <h1 className="text-3xl md:text-4xl font-bold text-white mb-8 font-[Space_Grotesk]">
          Portfolio
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-6">
          <BentoCard project={projects[0]} className="md:col-span-2 md:row-span-2 h-[600px]" />
          <BentoCard project={projects[1]} className="h-[300px]" />
          <BentoCard project={projects[2]} className="h-[300px]" />
          <BentoCard project={projects[3]} className="md:col-span-2 h-[300px]" />
          <BentoCard project={projects[4]} className="h-[300px]" />
        </div>
      </div>
      <CosmicFooter />
    </div>
  );
};

export default PortfolioPage;
