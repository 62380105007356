import React from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';


const CosmicInfoSection = () => {

  const navigate = useNavigate();

  return (
    <div className="w-full bg-gradient-to-br from-[#301934] to-[#301934] py-16 md:py-24">
      <div className="container mx-auto px-4 md:px-8">
        <div className="flex flex-col md:flex-row items-start justify-between md:space-x-12">
          <motion.div
            className="w-full md:w-1/2 mb-8 md:mb-0"
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
          >
            <h2 className="text-4xl md:text-5xl font-bold mb-6 text-white" style={{ fontFamily: 'Space Grotesk, sans-serif' }}>
              Isha's Work
            </h2>
            <p className="text-lg md:text-xl text-white mb-6" style={{ fontFamily: 'Space Grotesk, sans-serif' }}>
              From conceptualizing immersive Roblox experiences for National Geographic to curating virtual fashion events for Teen Vogue, Isha seamlessly blends creativity with strategic brand integration in the digital realm.
            </p>
            <p className="text-lg md:text-xl text-white mb-6" style={{ fontFamily: 'Space Grotesk, sans-serif' }}>
              Her unique approach transforms educational content into engaging virtual adventures, sparking curiosity and empowering exploration across diverse platforms.
            </p>
            <p className="text-lg md:text-xl text-white mb-8" style={{ fontFamily: 'Space Grotesk, sans-serif' }}>
              With a keen eye for detail and a passion for pushing boundaries, Isha crafts multiverses that not only captivate audiences but also drive meaningful brand interactions and awareness.
            </p>
            <motion.button
              className="px-6 py-3 bg-gray-800 text-white rounded-full hover:bg-gray-700 transition duration-300"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => navigate('/portfolio')}
            >
              Explore My Work
            </motion.button>
          </motion.div>
          <motion.div
            className="w-full md:w-1/2"
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
          >
            <img
              src="media/isha.png"
              alt="Cosmic Design Illustration"
              className="w-full h-auto rounded-lg shadow-2xl"
            />
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default CosmicInfoSection;
