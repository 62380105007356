import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Mail, Phone, Linkedin } from 'lucide-react';

const BusinessCardModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <motion.div
            className="bg-white p-8 rounded-lg shadow-2xl max-w-sm w-full transform rotate-3"
            initial={{ scale: 0.9, rotate: 0 }}
            animate={{ scale: 1, rotate: 3 }}
            exit={{ scale: 0.9, rotate: 0 }}
            style={{
              backgroundImage: 'url("https://www.transparenttextures.com/patterns/cream-paper.png")',
              boxShadow: '0 10px 25px rgba(0, 0, 0, 0.5), 0 2px 5px rgba(0, 0, 0, 0.3)'
            }}
          >
            <div className="text-center mb-6 relative">
              <div className="absolute -top-4 -left-4 w-16 h-16 bg-[#301934] rounded-full opacity-50"></div>
              <img
                src="media/isha.png"
                alt="Isha Desai"
                className="w-32 h-32 rounded-full mx-auto mb-4 border-4 border-[#301934] shadow-lg"
              />
              <h2 className="text-2xl font-bold text-gray-800" style={{ fontFamily: 'Space Grotesk, sans-serif' }}>Isha Desai</h2>
              <p className="text-gray-600 font-semibold" style={{ fontFamily: 'Space Grotesk, sans-serif' }}>Branding Specialist</p>
            </div>
            <div className="space-y-3">
              <a href="mailto:isha.desai@gmail.com" className="flex items-center text-gray-700 hover:text-[#301934] transition-colors">
                <Mail className="w-5 h-5 mr-2" />
                <span style={{ fontFamily: 'Space Grotesk, sans-serif' }}>isha.desai@gmail.com</span>
              </a>
              <a href="https://www.linkedin.com/in/ishadesai2/" target="_blank" rel="noopener noreferrer" className="flex items-center text-gray-700 hover:text-[#301934] transition-colors">
                <Linkedin className="w-5 h-5 mr-2" />
                <span style={{ fontFamily: 'Space Grotesk, sans-serif' }}>LinkedIn Profile</span>
              </a>
            </div>
            <motion.button
              onClick={onClose}
              className="mt-8 bg-[#301934] text-white py-2 px-6 rounded-full w-full font-semibold hover:bg-[#301934] transition-colors"
              style={{ fontFamily: 'Space Grotesk, sans-serif' }}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Close
            </motion.button>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default BusinessCardModal;
