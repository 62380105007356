import React from 'react';
import { Linkedin, Mail } from 'lucide-react';

const CosmicFooter = () => {
  const socialLinks = [
    { icon: Linkedin, href: "https://www.linkedin.com/in/ishadesai2/" },
    { icon: Mail, href: "mailto:isha.desai@gmail.com" },
  ];

  return (
    <footer className="bg-gradient-to-b from-[#301934] to-[#2C3E50] text-white py-8 w-full">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="mb-4 md:mb-0">
            <h3 className="text-xl md:text-2xl font-bold mb-1 md:mb-2" style={{ fontFamily: 'Space Grotesk, sans-serif' }}>Isha Desai</h3>
            <h3 className="text-xs md:text-sm" style={{ fontFamily: 'Space Grotesk, sans-serif' }}>Crafting digital universes and brand experiences</h3>
          </div>
          <div className="flex space-x-4 md:space-x-6">
            {socialLinks.map((link, index) => (
              <a
                key={index}
                href={link.href}
                target="_blank"
                rel="noopener noreferrer"
                className="text-white hover:text-gray-300 transition-colors duration-300"
              >
                <link.icon size={20} />
              </a>
            ))}
          </div>
        </div>
        <div className="mt-4 md:mt-6 pt-4 md:pt-6 border-t border-white border-opacity-20 text-center">
          <h4 className="text-xs md:text-sm" style={{ fontFamily: 'Space Grotesk, sans-serif' }}>
            © {new Date().getFullYear()} Isha Desai. All rights reserved.
          </h4>
        </div>
      </div>
    </footer>
  );
};

export default CosmicFooter;
