import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MultiversePortfolio from './components/Portfolio';
import PortfolioPage from './components/PortfolioPage';
import CosmicNavbar from './components/Navbar';

function App() {
  return (
    <Router>
      <CosmicNavbar />
      <Routes>
        <Route path="/" element={<MultiversePortfolio />} />
        <Route path="/portfolio" element={<PortfolioPage />} />
      </Routes>
    </Router>
  );
}

export default App;
